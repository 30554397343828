import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DesignService {

  
     // Private
     private _design: BehaviorSubject<any | null>;
     private _config: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
    )
    {

        // Set the private defaults
        this._design = new BehaviorSubject(null);
        this._config = JSON.parse(localStorage.getItem('cache:config'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get for design
     */
     get design$(): Observable<any>
     {
         return this._design.asObservable();
     }
 

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Design 
     */
    getDesign(): Observable<any>
    {
        if (!this._config) {
            return;
        }

        return this._httpClient.get<any>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/design`).pipe(
            tap((response: any) => {
                // Set design
                localStorage.setItem('cache:design', JSON.stringify(response));

                // Update orders
                return this._design.next(response);
            })
        );
    }

}
