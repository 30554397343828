import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Salesman} from './salesman.types';
import {tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SalesmanService
{
    // Private
    private _salesman: BehaviorSubject<Salesman[] | null>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
    )
    {
        // Set the private defaults
        this._salesman = new BehaviorSubject(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get for salesman
     */
    get salesman$(): Observable<Salesman[]>
    {
        return this._salesman.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all salesman
     */
    getSalesman(): Observable<Salesman[]>
    {
        const config = JSON.parse(localStorage.getItem('cache:config'));
        if (!config) {
            return;
        }

        if(localStorage.getItem('cache:network-error')){
            let salesmans = JSON.parse(localStorage.getItem('cache:salesmans'));
            return salesmans;
        }

        return this._httpClient.get<Salesman[]>(`http://${config.ip}:${config.port}/inovarservice-order/public/salesman`).pipe(
            tap((response: Salesman[]) => {

                // Store salesmans in cache
                localStorage.setItem('cache:salesmans', JSON.stringify(response));
                localStorage.removeItem('cache:network-error');

                this._salesman.next(response);
                return response;
            }),
        );
    }
}
