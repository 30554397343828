import {Inject, Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Group, Product} from './product.types';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductService
{
    // Private
    private _products: BehaviorSubject<Product[] | null>;
    private _product: BehaviorSubject<Product | null>;
    private _groups: BehaviorSubject<Group[] | null>;

    private _config: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
    )
    {
        // Set the private defaults
        this._products = new BehaviorSubject(null);
        this._product = new BehaviorSubject(null);
        this._groups = new BehaviorSubject(null);

        this._config = JSON.parse(localStorage.getItem('cache:config'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get for products
     */
    get products$(): Observable<Product[]>
    {
        return this._products.asObservable();
    }

    /**
     * Get for products
     */
    get groups$(): Observable<Group[]>
    {
        return this._groups.asObservable();
    }

    /**
     * Get for product
     */
    get product$(): Observable<Product>
    {
        return this._product.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all products
     *
     * @param query
     * @param group
     */
    getProducts(query: string = '', group: string): Observable<Product[]>
    {
        if (!this._config) {
            return;
        }

        return this._httpClient.get<Product[]>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/product?q=${query}&c=${group}`).pipe(
            tap((products: Product[]) => {
                // Update products
                this._products.next(products);
            })
        );
    }

    /**
     * Get all groups
     */
    getGroups(): Observable<Group[]>
    {
        if (!this._config) {
            return;
        }

        return this._httpClient.get<Group[]>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/category`).pipe(
            tap((groups: Group[]) => {
                // Update products
                this._groups.next(groups);
            })
        );
    }

    /**
     * Get product
     *
     * @param id
     */
    getProduct(id: string)
    {
        const config = JSON.parse(localStorage.getItem('cache:config'));
        if (!config) {
            return;
        }

        return this._httpClient.get<Product>(`http://${config.ip}:${config.port}/inovarservice-order/public/product/${id}`)
            .subscribe((response: Product) => {
                this._product.next(response);
            });
    }

    clearProduct()
    {
        this._product.next(null);
    }
}
