import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrintersService {

  
     // Private
     private _printers: BehaviorSubject<any | null>;
     private _config: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
    )
    {

        // Set the private defaults
        this._printers = new BehaviorSubject(null);
        this._config = JSON.parse(localStorage.getItem('cache:config'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get for printers
     */
     get printers$(): Observable<any>
     {
         return this._printers.asObservable();
     }
 

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Printers 
     */
    getPrinters(): Observable<any>
    {
        if (!this._config) {
            return;
        }

        return this._httpClient.get<any>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/printers`).pipe(
            tap((response: void) => {
                // Set printers
                localStorage.setItem('cache:printers', JSON.stringify(response));
                return this._printers.next(response);
            })
        );
    }

}
